import React from 'react'
// import Odometer from 'react-odometerjs'
import CountUp from 'react-countup'
const AboutCounterPage = () => {
  return (
   <>
            <section className="counter-area-three white-background pb-100 pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-0 order-lg-2">
                            <div className="counter-item-wrap-three">
                                <ul className="list-wrap">
                                    <li>
                                        <div className="counter-item-three wow bounceInUp">
                                            <div className="icon">
                                                <img src="assets/img/icon/inner_counter_icon01.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <h2 className="count"><span className=""></span><CountUp start={1} end={23}  duration={ 4 } />+</h2>
                                                <p>Satisfied <span>Customers</span></p>
                                            </div>
                                            
                                        </div>
                                    </li>
                                    <li>
                                        <div className="counter-item-three  wow bounceInDown">
                                            <div className="icon">
                                                <img src="assets/img/icon/inner_counter_icon02.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <h2 className="count"><span className="" datacount={15}></span><CountUp start={1} end={21}  duration={ 4 } />+</h2>
                                                <p>Project <span>Finished</span></p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="counter-item-three  wow bounceInUp">
                                            <div className="icon">
                                                <img src="assets/img/icon/inner_counter_icon03.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <h2 className="count"><span className="" datacount={110}></span><CountUp start={1} end={50}  duration={ 4 } />+</h2>
                                                <p>Our <span>Employees</span></p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="counter-item-three  wow bounceInDown">
                                            <div className="icon">
                                                <img src="assets/img/icon/inner_counter_icon04.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <h2 className="count"><span className="" ></span><CountUp start={1} end={5}  duration={ 4 } />+</h2>
                                                <p>Current <span>Client</span></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft">
                            <div className="counter-content-three">
                                <div className="section-title title-style-two mb-30">
                                    <h2 className="title center-title">We provide data analytics & data solutions</h2>
                                </div>
                                <p className=''> Real-time data analytics and data solutions can be very valuable in today's fast-paced business environment. By analyzing data in real-time, you can make more informed decisions quickly and respond to changing conditions or opportunities faster than your competitors.There are many different types of data solutions Stream processing,In-memory databases,Machine learning algorithms.</p> 
                                {/* <div className="content-bottom">
                                    <h4 className="title-two ">Davis Levin</h4>
                                    <span className=''>Director Company</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- counter-area-two --> */}
   </>
  )
}

export default AboutCounterPage
