import React from 'react'

function Work() {
    return (
        <section className="banner-area-two work">
            <div className="container">
                <div className="banner-content-two wow fadeInRight">

                    <h2 className="title text-white text-center ">How  <span className='work_span'>It's</span> works</h2>
                    
                
                </div>

                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-5">
                        <div className="banner-img-two wow fadeInLeft ">
                            <img src="assets/img/Service/girls.png"  alt="" className='mt-5' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="banner-content-two wow fadeInRight ">

                          
                            <h4 className='text-white work_h4 mt-5'><span>Working Process</span></h4>
                            <p className='text process_work'>"Our  process includes several stages such as requirement analysis and planning, followed by database designing which involves conceptual, logical, and physical design. Finally, the implementation phase includes tasks such as data conversion and loading, as well as testing."</p>   </div>

                        </div>
                    </div>
                </div>
           

        </section>
    )
}

export default Work