import React from 'react'
import AboutCounterPage from './AboutCounterPage'
import AboutService from './AboutService'
import CompanyCuccess from './CompanyCuccess'
import Consutation from './Consutation'
import TeamPage from './TeamPage'
import History from './History'
 import TestimationPage from '../common/TestimoniaPage' 
import {Link} from 'react-router-dom';
// import BrandPage from '../hero-section/BrandPage'

const AboutUs = () => {
    
  return (
   <>
    <main>
    {/* <!-- breadcrumb-area --> */}
            <section className="breadcrumb-area pt-175 pb-140">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="title text-white wow zoomIn">About Us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                        <li className="breadcrumb-item " aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-shape-wrap">
                   
                    <img src="assets/img/images/setting.png" alt=""  height={150}/>
                    <img src="assets/img/images/breadcrumb_shape01.png" alt=""  />
                </div>
            </section>
            {/* <!-- breadcrumb-area-end --> */}


<AboutCounterPage/>
<TeamPage/>
<Consutation/>
{/* <AboutService/> */}
<History/>
<CompanyCuccess/>

<TestimationPage/>


{/* <section className="brand-area-two pt-110  ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center mb-80">
                                <span className="sub-title"></span>
                                <h2 className="title">We are Already Build Solution for...</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row brand-active">
                      <BrandPage/>
                    </div>
                </div>
            </section> */}

    </main>
   </>
  )
}

export default AboutUs
