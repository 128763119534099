import React from 'react'
//import Odometer from 'react-odometerjs'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'

const AboutCounterPage = () => {
    return (
        <>

            {/* <!-- about-area --> */}
            <section className="about-area-two">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-0 order-lg-2">
                            <div className="about-img-two text-center  wow zoomIn">
                                <img src="assets/img/images/h2_about_img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-two">
                                <div className="section-title white-title title-style-two mb-30">
                                    {/* <span className="sub-title">I'm a Designer</span> */}
                                    <h2 className="title wow fadeInLeft center-title">We enhance your Database</h2>
                                </div>
                                <p className=' wow fadeInRight'>Hello There.! We are Professional Database Developers with expertise in Database Designing, Development, Re-Structuring and Optimization. We also have excellency in Data De-duplication using various Fuzzy and Soundex Algorithms.</p>
                                <Link to='/service'>
                                    <span className="arrow"><img src="assets/img/icon/right_arrow.svg" fillRule="evenodd" alt="" /></span>
                                   <Link to='/service'><span className="text">Learn More</span></Link>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-shape-wrap">
                    <img src="assets/img/images/h2_about_shape01.png" alt="" className="shape-one" data-parallax='{"y" : 100 }' />
                    <img src="assets/img/images/h2_about_shape02.png" alt="" className="shape-two" />
                    <img src="assets/img/images/h2_about_shape03.png" alt="" className="shape-three" />
                </div>
            </section>


            {/* <!-- counter-area --> */}
            <div className="counter-area">
                <div className="container">
                    <div className="counter-item-wrap">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <span className="count " ><CountUp start={1} end={21} duration={4} /></span>
                                    <p>Project <span>Completed</span></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <span className="count" ><CountUp start={1} end={23} duration={4} /></span>
                                    <p>Our Happy <span>Clients</span></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <span className="count "> <CountUp start={1} end={23} duration={4} /></span>
                                    <p>Cup of <span>Coffee for Me</span></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <span className="count " ><CountUp start={1} end={5} duration={4} /></span>
                                    <p>Clients Work <span>With Me</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- counter-area-end --> */}
        </>
    )
}

export default AboutCounterPage