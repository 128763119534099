import React from 'react'

const History = () => {
  return (
   <>
      {/* <!-- history-area --> */}
            <section className="history-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title white-title text-center mb-45">
                                <span className="sub-title">Our Company</span>
                                <h2 className="title">Company History</h2>
                                <p>The Company started as a small start-up with a handful of Employees, but has since grown into a leading provider of data-driven solutions for businesses of all sizes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="history-inner">
                        <div className="history-img">
                            <img src="assets/img/images/history_img.jpg" alt="" />
                        </div>
                        <div className="row g-0 justify-content-end">
                            <div className="col-lg-6">
                                <div className="history-content">
                                    <h2 className="title center-title">The Intention was to Establish The Company</h2>
                                    <p>We are passionate about helping our clients succeed and achieve their goals. That's why we take a collaborative approach to every project we undertake. We work closely with our clients to understand their unique needs and requirements, and we tailor our solutions to meet their specific business objectives.</p>
                                    <ul className="list-wrap">
                                        <li><i className="far fa-check"></i>Decision-making</li>
                                        <li><i className="far fa-check"></i>Increased Efficiency</li>
                                        <li><i className="far fa-check"></i>Improved Customer Experience</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="history-roadmap-wrap">
                        <div className="roadmap-line-shape">
                            <span className="dot-one pulse-one"></span>
                            <span className="dot-two pulse-two"></span>
                        </div>
                        <ul className="list-wrap">
                            <li className="wow fadeInDown" data-wow-delay=".2s" data-wow-duration="1.5s">
                                <span className="dot pulse-three"></span>
                                <div className="content">
                                    <h5 className="title">2020</h5>
                                    <p>January 14 th</p>
                                </div>
                            </li>
                            <li className="wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1.5s">
                                <span className="dot pulse-four"></span>
                                <div className="content">
                                    <h5 className="title">2021</h5>
                                    <p>January 14 th</p>
                                </div>
                            </li>
                            <li className="wow fadeInDown" data-wow-delay=".2s" data-wow-duration="1.5s">
                                <span className="dot pulse-five"></span>
                                <div className="content">
                                    <h5 className="title">2022</h5>
                                    <p>January 14 th</p>
                                </div>
                            </li>
                            <li className="wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1.5s">
                                <span className="dot pulse-six"></span>
                                <div className="content">
                                    <h5 className="title">2023</h5>
                                    <p>January 14 th</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="history-shape-wrap">
                    <img src="assets/img/images/history_shape01.png" alt="" />
                    <img src="assets/img/images/history_shape02.png" alt="" />
                </div>
            </section>
            {/* <!-- history-area-end --></div> */}
   </>
  )
}

export default History
