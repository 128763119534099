import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

const ServiceProjectPage = () => {
    const breakpoints = {
        320: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,

        }
    };
    return (
        <>

            {/* <!-- services-area --> */}
            <section className="services-area-two">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title title-style-two white-title mb-65 text-center">
                                {/* <span className="sub-title">What I Do</span> */}
                                <h2 className="title">What Service Do We Provide</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <Swiper
                                    breakpoints={breakpoints}
                                    modules={[Pagination]}
                                    pagination={{ clickable: true }}
                                    spaceBetween={40}
                                    autoplay={{ delay: 2000 }}

                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log()}
                                >

<SwiperSlide>
<div className="col-lg-12 col-md-12 col-sm-12 mx-3">
                            <div className="services-item-two active wow bounceInUp">
                                <div className="services-icon-two">
                                    <img src="assets/img/icon/services_icon01.png" alt="" />
                                </div>
                                <div className="services-content-two">
                                    <h2 className="title fs-5"><a href=" "> Database Designing </a></h2>
                                    <p className='text-white'>
                                        We design optimized database right from the scratch
                                        by choosing the right data type and avoid saving the duplicate data. Divides your information tables to reduce  data
                                    </p>
                                </div>
                            </div>
                        </div>
    </SwiperSlide> 
    <SwiperSlide>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-3">
                            <div className="services-item-two active wow bounceInUp">
                                <div className="services-icon-two ">
                                    <img src="assets/img/icon/services_icon02.png" alt="" />
                                </div>
                                <div className="services-content-two">
                                    <h2 className="title fs-5"><a href=" ">Query Optimization</a></h2>
                                    <p className='text-white'> Re-Structuring of Database and Query Optimization,
                                        Reduction in Memory/Time  by analysing the execution plan
                                        and choosing the best way to achieve the expected output.</p>
                                </div>
                            </div>
                        </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-3">
                            <div className="services-item-two active wow bounceInUp">
                                <div className="services-icon-two">
                                    <img src="assets/img/icon/services_icon04.png" alt="" />
                                </div>
                                <div className="services-content-two">
                                    <h2 className="title fs-5"><a href=" ">Database Development</a></h2>
                                    <p className='text-white'>Database Development refers to the process of creating and maintaining a database by designing, and relationships to meet specific business needs.</p>  </div>
                            </div>
                        </div>
    </SwiperSlide>

    <SwiperSlide>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-3">
                            <div className="services-item-two active wow bounceInUp">
                                <div className="services-icon-two">
                                    <img src="assets/img/icon/services_icon03.png" alt="" />
                                </div>
                                <div className="services-content-two">
                                    <h2 className="title fs-5"><a href=" ">Data Deduplication</a></h2>
                                    <p className='text-white'>Data Deduplication is the process of identifying and removing duplicate data from a dataset to optimize storage efficiency, and reduce overall costs</p> </div>
                            </div>
                        </div>

    </SwiperSlide>
    <SwiperSlide>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-3">
                            <div className="services-item-two active wow bounceInUp">
                                <div className="services-icon-two">
                                    <img src="assets/img/icon/python-scripting.png" alt="" />
                                </div>
                                <div className="services-content-two">
                                    <h2 className="title fs-5"><a href=" ">
                                        Python Development</a></h2>
                                    <p className='text-white'> Python is a high-level programming language used for web development, data analysis, artificial intelligence, and more, with a concise syntax and extensive libraries.</p> </div>
                            </div>
                        </div>
    </SwiperSlide>
                        </Swiper>

                       

                        

                       

                        
                    
                    </div>
                </div>



            </section>
            {/* <!-- services-area-end --> */}


            {/* <!-- project-area --> */}
            {/* <section className="project-area-two pt-110 pb-90">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-9">
                                <div className="section-title title-style-two white-title mb-60 text-center">
                                    <span className="sub-title">Portfolio</span>
                                    <h2 className="title">You Will Like These Design Made by Me</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                                </div>
                            </div>
                        </div>
                        <div className="project-item-wrap">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="project-menu-nav">
                                        <button className="active" data-filter="*">View All</button>
                                        <button className="" data-filter=".cat-one">App</button>
                                        <button className="" data-filter=".cat-two">Website</button>
                                        <button className="" data-filter=".cat-three">Landing-page</button>
                                        <button className="" data-filter=".cat-four">Branding-app</button>
                                        <button className="" data-filter=".cat-five">Product Design</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row project-active-two">
                                <div className="col-lg-4 col-md-6 grid-item grid-sizer cat-three cat-two">
                                    <div className="project-item-two">
                                        <div className="project-thumb-two">
                                            <a href="project-details.html"><img src="assets/img/project/h2_project_img01.jpg" alt="" /></a>
                                        </div>
                                        <div className="project-content-two">
                                            <h2 className="title"><a href="project-details.html">Motion Design</a></h2>
                                            <span>Lorem Ipsum is simply</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 grid-item grid-sizer cat-four cat-five">
                                    <div className="project-item-two">
                                        <div className="project-thumb-two">
                                            <a href="project-details.html"><img src="assets/img/project/h2_project_img02.jpg" alt="" /></a>
                                        </div>
                                        <div className="project-content-two">
                                            <h2 className="title"><a href="project-details.html">Motion Design</a></h2>
                                            <span>Lorem Ipsum is simply</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 grid-item grid-sizer cat-two cat-one">
                                    <div className="project-item-two">
                                        <div className="project-thumb-two">
                                            <a href="project-details.html"><img src="assets/img/project/h2_project_img03.jpg" alt="" /></a>
                                        </div>
                                        <div className="project-content-two">
                                            <h2 className="title"><a href="project-details.html">Motion Design</a></h2>
                                            <span>Lorem Ipsum is simply</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 grid-item grid-sizer cat-four cat-five">
                                    <div className="project-item-two">
                                        <div className="project-thumb-two">
                                            <a href="project-details.html"><img src="assets/img/project/h2_project_img05.jpg" alt="" /></a>
                                        </div>
                                        <div className="project-content-two">
                                            <h2 className="title"><a href="project-details.html">Motion Design</a></h2>
                                            <span>Lorem Ipsum is simply</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 grid-item grid-sizer cat-one cat-five">
                                    <div className="project-item-two">
                                        <div className="project-thumb-two">
                                            <a href="project-details.html"><img src="assets/img/project/h2_project_img04.jpg" alt="" /></a>
                                        </div>
                                        <div className="project-content-two">
                                            <h2 className="title"><a href="project-details.html">Motion Design</a></h2>
                                            <span>Lorem Ipsum is simply</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 grid-item grid-sizer cat-two cat-five">
                                    <div className="project-item-two">
                                        <div className="project-thumb-two">
                                            <a href="project-details.html"><img src="assets/img/project/h2_project_img06.jpg" alt="" /></a>
                                        </div>
                                        <div className="project-content-two">
                                            <h2 className="title"><a href="project-details.html">Motion Design</a></h2>
                                            <span>Lorem Ipsum is simply</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project-shape">
                        <img src="assets/img/project/h2_project_shape.png" alt="" />
                    </div>
                </section> */}
            {/* <!-- project-area-end --> */}

        </>
    )
}

export default ServiceProjectPage