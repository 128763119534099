import React from 'react'
import BrandPage from '../../home/BrandPage'
import FaqPage from '../../common/FaqPage'
import ProjectPage from '../team-detail/ProjectPage'
import TestimonialPage from '../team-detail/TestimonialPage'
import ToolPage from '../../common/ToolPage'
import DeveloperPage from './DeveloperPage'

const AboutMe = () => {
  return (
   <>
        
        <main>

            {/* <!-- breadcrumb-area --> */}
            <section className="breadcrumb-area about-me-breadcrumb pt-175 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="title">About Me</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">About Me</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-shape-wrap">
                    <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
                    <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
                </div>
            </section>
           
           <DeveloperPage/>

           <ToolPage/>

           <FaqPage/>


           
   {/* <!-- client-area --> */}
   <section className="client-area pt-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="section-title title-style-two text-center mb-55">
                                    <span className="sub-title">My Clients</span>
                                    <h2 className="title">I Work With Over 175+ Happy Client</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row brand-active">
                            <BrandPage/>
                        </div>
                    </div>
                </section>
                {/* <!-- client-area-end --> */}

          
          <ProjectPage/>
          <TestimonialPage/>
          
          
          
     
        </main>
        {/* <!-- main-area-end --> */}

   </>
  )
}

export default AboutMe