import React from 'react'

import { Link } from 'react-router-dom'
const TeamPage = () => {

    return (
        <>
            {/* <!-- team-area --> */}
            <section className="team-area-two pt-110 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7">
                            <div className="section-title title-style-two text-center mb-70">
                                <h2 className="title text-white">Meet The Squad</h2>
                                <p className='text-white-50'>Meet the passionate and skilled individuals who make up our team and are committed to delivering exceptional results for our clients.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">

            
<center>
<div className="col-md-8 col-sm-12  ">
                                <div className="team-item ">
                                    <div className="team-thumb">
                                        <Link to='/'><img src="assets/img/team/man.png" className='meet' alt="" height={450}/></Link>
                                    </div>
                                    <div className="team-content ">
                                        <h2 className="title"><Link to='/about'>DatoGix</Link></h2>
                                        <span className=''>CEO </span>
                                        {/* <span className=''>Professional Database Developer <br/> with 11+ years of experience</span> */}

                                        <div className="team-social">
                                            {/* <ul class="list-wrap">
                                            <li><a href="# "><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="# "><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="# "><i class="fab fa-behance"></i></a></li>
                                        </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
</center>
                       






                    </div>
                </div>
            </section>
            {/* <!-- team-area-end --> */}
        </>
    )
}
export default TeamPage