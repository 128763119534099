import React from 'react'

const ToolPage = () => {
  return (
   <>
  {/* <!-- tools-area --> */}
            <section className="tools-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title title-style-two white-title text-center mb-50">
                                <span className="sub-title">My Tools</span>
                                <h2 className="title">What Tools I Use</h2>
                            </div>
                        </div>
                    </div>
                    <div className="tools-item-wrap">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon01.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">Photoshop</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon02.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">Illustrator</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon03.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">Figma</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon04.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">Sketch</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon05.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">Premiere Pro</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon06.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">After Effects</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon07.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">HTML 5</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                <div className="tools-item">
                                    <div className="tools-icon">
                                        <img src="assets/img/icon/tools_icon08.png" alt=""/>
                                    </div>
                                    <div className="tools-content">
                                        <h3 className="title">Blender</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- tools-area-end --> */}

   </>
  )
}

export default ToolPage