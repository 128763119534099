//import logo from './logo.svg';
import './App.css';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import HomePage from './component/home/HomePage';
import TeamPage from './component/Pages/team/TeamPage';
import {Routes,Route} from 'react-router-dom'
import TeamDetail from './component/Pages/team-detail/TeamDetail';
import Portfolio from './component/Pages/portfolio/Portfolio';
// import ServicesPage from './component/Pages/services/ServicesPage';
import AboutMe from './component/Pages/aboutme/AboutMe';
import BlogPage from './component/Pages/news/BlogPage';
import BlogDetail from './component/Pages/news/BlogDetail';
import AboutUs from './component/about/AboutUs';
// import ContactPage from './component/Pages/contact/ContactPage';
import Service from './component/services/Service';
import ContactUsPAge from './component/contactus/ContactUsPAge';

function App() {
  return (
    <>
  <Navbar/>
   <Routes>
   <Route exact path='/' element={<HomePage/>}></Route>
   <Route exact path='/about' element={<AboutUs/>}></Route>
    <Route exact path='/team' element={<TeamPage/>}></Route>
    <Route exact path='/team-detail' element={<TeamDetail/>}></Route>
    <Route exact path='/portfolio' element={<Portfolio/>}></Route>
    <Route exact path='/service' element={<Service/>}></Route>
    <Route exact path='/aboutme' element={<AboutMe/>}></Route>
    <Route exact path='/blog' element={<BlogPage/>}></Route>
    <Route exact path='/blog-detail' element={<BlogDetail/>}></Route>
    <Route exact path='/contact' element={<ContactUsPAge/>}></Route>
   </Routes>
   <Footer/>
   
    {/* <Navbar/>
    <HeroSection/>
    */}
   
    </>
  );
}

export default App;
