import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { useLocation } from 'react-router-dom';



const TestimoniaPage = () => {
    let location = useLocation();
    useEffect(() => {

    });
    const breakpoints = {
        320: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 1,

        }
    };
    return (
        <>
            {/* <!-- testimonial-area --> */}
            {location.pathname === '/' ?
                <>
                    <section className="testimonial-area-two pb-110 ">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-9">
                                    <div className="section-title title-style-two white-title text-center mb-60">
                                        <span className="sub-title  wow bounceInUp">Testimonial</span>
                                        <h2 className="title wow bounceInDown">Word From Our Client</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row testimonial-active-twoR">



                                <Swiper
                                    breakpoints={breakpoints}
                                    modules={[Pagination]}
                                    pagination={{ clickable: true }}
                                    spaceBetween={0}
                                    autoplay={{ delay: 2000 }}

                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log()}
                                >
                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                            <div className="testimonial-item-two">
                                                <div className="testimonial-content-two">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        {/* <i className="fas fa-star"></i> */}
                                                    </div>
                                                    <p>‘’Naveen is exceptional. Thoughtfully approached the problem and was not only a programmer,
                                                        but collaborated on how to achieve the desired outcomes. He was able to use proficiency in
                                                        Fuzzy logic, Sql, and python languages to effectively and efficiently reach the desired goal.

                                                        He also quickly understood what was requested, and asked clarifying questions where needed.
                                                        His work was always punctual, and he was prepared for each meeting that we had.

                                                        I plan on hiring him again in the future as more projects become available.”</p>
                                                </div>
                                                <div className="testimonial-info-two">
                                                    <div className="thumb">
                                                        <img src="assets/img/images/testimonial_avatar03.png" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">Jason Teed</h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div></SwiperSlide>


                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                            <div className="testimonial-item-two">
                                                <div className="testimonial-content-two">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <p>‘’Naveen thoroughly reviewed our database design and offered excellent comments. He quickly

                                                        understood what we were planning fo achieve and answered all our questions. We look forward to
                                                        work with him in the future”</p>
                                                </div>
                                                <div className="testimonial-info-two">
                                                    <div className="thumb">
                                                        <img src="assets/img/images/testimonial_avatar01.png" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">Shivaprakash Aduvalli </h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div></SwiperSlide>

                                    <SwiperSlide>   <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                        <div className="testimonial-item-two">
                                            <div className="testimonial-content-two">
                                                <div className="rating">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                </div>
                                                <p>‘’Great work & support provided. Looking forward to work more in future.”</p>
                                            </div>
                                            <div className="testimonial-info-two">
                                                <div className="thumb">
                                                    <img src="assets/img/images/testimonial_avatar04.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="title">Steve Ray</h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div></SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                            <div className="testimonial-item-two">
                                                <div className="testimonial-content-two">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <p>‘’One of the best person found over upwork. He is very committed to his work and also have sound
                                                        understanding about what is needed. Will continue for more work in future.”</p>
                                                </div>
                                                <div className="testimonial-info-two">
                                                    <div className="thumb">
                                                        <img src="assets/img/images/testimonial_avatar02.png" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">Marc Tybout</h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto">
                                            <div className="testimonial-item-two">
                                                <div className="testimonial-content-two">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <p>‘’ Naveen is an excellent Free lancer. He is technically very strong. He
                                                        meets all the deadlines. He will give solutions to the problems and find
                                                        the route cause of the issue. He has very good communication. I asked
                                                        for additional miles stone he accepted it instantly. The task that Naveen
                                                        completed is very challenging where other Free lancer were unable to

                                                        give a solution to it. Naveen is highly talented and wonderful to work
                                                        with.”</p>
                                                </div>
                                                <div className="testimonial-info-two">
                                                    <div className="thumb">
                                                        <img src="assets/img/images/testimonial_avatar01.png" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title"> Krish Balaraman</h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div></SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto">
                                            <div className="testimonial-item-two">
                                                <div className="testimonial-content-two">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <p>‘’Naveen expertise in postgresql and shell scripting helped me to load ETL related json with large
                                                        sets of with a very complex nested json. His availability made the project delivery prior to dead
                                                        lines. And he is very quick grasper and quickly he can understand the requirement and effort and
                                                        can guide you easy spots on the delivery on any enhancements or optimizations on the sql query.
                                                        I would definitely come back to him if there's any sql oriented, or postgres related effort.”</p>
                                                </div>
                                                <div className="testimonial-info-two">
                                                    <div className="thumb">
                                                        <img src="assets/img/images/testimonial_avatar02.png" alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">Ravi Teja</h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>





                                </Swiper>




                            </div>
                        </div>
                    </section>
                </>

                :
                (location.pathname === '/about') ? <>
                    <section className="testimonial-area-three testimonial-area-four  white-background">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <div className="section-title title-style-two mb-45">
                                        <h2 className="title  wow fadeInRight text-center">What Our Client’s Say</h2>

                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                            <div className="testimonial-nav">
                                <button className="swiper-button-prev"></button>
                                <button className="swiper-button-next"></button>
                            </div>
                        </div> */}
                            </div>
                            <div className="row">

                                <Swiper
                                    breakpoints={breakpoints}
                                    modules={[Pagination]}
                                    pagination={{ clickable: true }}
                                    spaceBetween={10}
                                    autoplay={{ delay: 2000 }}

                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log()}
                                >

                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                            <div className="testimonial-item-three mx-4">
                                                <div className="testimonial-thumb-three  wow bounceInDown">
                                                    <img src="assets/img/images/testimonial_avatar01.png" alt="" />
                                                </div>
                                                <div className="testimonial-content-three">
                                                    <h4 className="title">Jason Teed</h4>

                                                    <p className='text-secondary'>“Naveen is exceptional. Thoughtfully approached the problem and was not only a programmer,
                                                        but collaborated on how to achieve the desired outcomes. He was able to use proficiency in
                                                        Fuzzy logic, Sql, and python languages to effectively and efficiently reach the desired goal.

                                                        He also quickly understood what was requested, and asked clarifying questions where needed.
                                                        His work was always punctual, and he was prepared for each meeting that we had.

                                                        I plan on hiring him again in the future as more projects become available.”</p>
                                                </div>
                                            </div>
                                        </div>



                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                            <div className="testimonial-item-three mx-4">
                                                <div className="testimonial-thumb-three wow bounceInDown">
                                                    <img src="assets/img/images/testimonial_avatar02.png" alt="" />
                                                </div>
                                                <div className="testimonial-content-three">
                                                    <h4 className="title">Shivaprakash Aduvalli</h4>

                                                    <p className='text-secondary'>“Naveen thoroughly reviewed our database design and offered excellent comments. He quickly

                                                        understood what we were planning fo achieve and answered all our questions. We look forward to
                                                        work with him in the future.”</p>
                                                </div>
                                            </div>
                                        </div>



                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-9  col-sm-12 mx-auto  wow fadeInRight">
                                            <div className="testimonial-item-three mx-4">
                                                <div className="testimonial-thumb-three">
                                                    <img src="assets/img/images/testimonial_avatar03.png" alt="" />
                                                </div>
                                                <div className="testimonial-content-three">
                                                    <h4 className="title"> Krish Balaraman</h4>

                                                    <p className='text-secondary'>“Naveen is an excellent Free lancer. He is technically very strong. He
                                                        meets all the deadlines. He will give solutions to the problems and find
                                                        the route cause of the issue. He has very good communication. I asked
                                                        for additional miles stone he accepted it instantly. The task that Naveen
                                                        completed is very challenging where other Free lancer were unable to
                                                        give a solution to it. Naveen is highly talented and wonderful to work
                                                        with.”</p>
                                                </div>
                                            </div>
                                        </div>


                                    </SwiperSlide>
                                </Swiper>


                            </div>
                        </div>
                    </section>
                </> : <>
                    <h1>hello</h1>
                </>
            }

            {/* <!-- testimonial-area-end --> */}




        </>
    )
}

export default TestimoniaPage
