import React from 'react'

const CompanyCuccess = () => {
    return (
        <>
            {/* <!-- success-area --> */}
            <section className="success-area pb-120 white-background pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-0 order-lg-2 wow fadeInRight">
                            <div className="success-img">
                                <img src="assets/img/images/success_img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="success-content wow fadeInLeft">
                                <div className="section-title title-style-two mb-30">
                                    <span className="sub-title center-title">Company Success</span>
                                    <h2 className="title center-title ">A Company’s Success is An Ongoing Stream of Happiness</h2>
                                </div>
                                <p className=''> company's success being an ongoing stream of happiness may not be directly related to data, there are many ways that it could be relevant to a data-related website, particularly in the context of employee engagement, productivity, and organizational performance.</p>
                                <div className="success-list">
                                    <ul className="list-wrap">
                                        <li>
                                            <div className="content">
                                                <img src="assets/img/icon/success_icon01.png" alt="" />
                                                <span className=''>Database Design</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '85%' }} aria-valuenow="85" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="content">
                                                <img src="assets/img/icon/success_icon02.png" alt="" />
                                                <span className=''>Database Optimization</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '75%' }} aria-valuenow="75" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="content">
                                                <img src="assets/img/icon/success_icon03.png" alt="" />
                                                <span className=''>Query Optimization,</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '95%' }} aria-valuenow="95" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="content">
                                                <img src="assets/img/icon/success_icon04.png" alt="" />
                                                <span className=''>Database Development</span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '99%' }} aria-valuenow="99" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="success-shape-wrap">
                    <img src="assets/img/images/success_shape01.png" alt="" />
                    <img src="assets/img/images/success_shape02.png" alt="" />
                </div>
            </section>
            {/* <!-- success-area-end --> */}

        </>
    )
}

export default CompanyCuccess