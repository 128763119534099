import React, { useState, useEffect } from 'react'
import DatabasePage from '../databasecom/DatabasePage'
import AboutCounterPage from './AboutCounterPage'
import Contact from './Contact'
import PricingWorkPage from './PricingWorkPage'
import ServiceProjectPage from './ServiceProjectPage'
import TestimationPage from '../common/TestimoniaPage'
import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect';

const HomePage = () => {


    
    return (
        <>
            <main>
                {/* <!-- banner-area --> */}
                <section className="banner-area-two">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-5">
                                <div className="banner-img-two wow fadeInLeft">
                                    <img src="assets/img/banner/banner_hero.jpg" alt="" className=""  />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7">
                                <div className="banner-content-two wow fadeInRight">

                                    <h2 className="title"> Professional<br />
                                        <span >Database </span>Consultancy Services</h2>
                                    <div className="banner-content-bottom">
                                        <Link to='/contact' className="btn-data btn-primary" >Contact Us <span></span></Link>
                                        <ul className="list-wrap">
                                            <li><a href=" "><img src="assets/img/icon/banner_icon01.svg" fillRule="evenodd" alt="" /></a></li>
                                            <li><a href="  "><img src="assets/img/icon/banner_icon02.svg" fillRule="evenodd" alt="" /></a></li>
                                            <li><a href="  "><img src="assets/img/icon/banner_icon03.svg" fillRule="evenodd" alt="" /></a></li>
                                            <Link to='/contact'>  <li><a href="  "><img src="assets/img/icon/banner_icon04.svg" fillRule="evenodd" alt="" /></a></li></Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-shape-wrap-two">
                        <img src="assets/img/banner/h2_banner_shape01.png" alt="" className="rotateme" />
                        <img src="assets/img/banner/h2_banner_shape02.png" alt="" className="ribbonRotate" />
                        <img src="assets/img/banner/h2_banner_shape03.png" alt="" className="ribbonRotate" />
                        <img src="assets/img/banner/h2_banner_shape04.png" alt="" />
                    </div>
                </section>


                {/* <BrandPage/> */}

                <DatabasePage />


                <AboutCounterPage />

                <ServiceProjectPage />

                <PricingWorkPage />

                <TestimationPage/>

                <Contact />





            </main>

        </>
    )
}

export default HomePage