import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
SwiperCore.use([Autoplay]);

const BrandPage = () => {
    const breakpoints = {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,

        },
        768: {
            slidesPerView: 4,
            spaceBetween: 20,
        },

    };
    return (
        <>

            {/* <!-- brand-area --> */}
            <div className="brand-area">
                <div className="container">
                    <div className="row brand-active">

                        <Swiper
                            breakpoints={breakpoints}
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={-10}
                            slidesPerView={5}
                            autoplay={{ delay: 2000 }}
                            onSwiper={(swiper) => console.log()}
                            onSlideChange={() => console.log()}
                        >

                            <SwiperSlide>
                                <div className="col-12 col-md-12">
                                    <div className="brand-item">
                                        <img src="assets/img/brand/Oracle_Logo.jpg" alt="" height={80}/>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="col-12 col-md-12">
                                    <div className="brand-item">
                                        <img src="assets/img/brand/brand_img02.png" alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="col-12 col-md-12">
                                    <div className="brand-item">
                                        <img src="assets/img/brand/brand_img03.png" alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="col-12 col-md-12">
                                    <div className="brand-item">
                                        <img src="assets/img/brand/brand_img04.png" alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="col-12 col-md-12">
                                    <div className="brand-item">
                                        <img src="assets/img/brand/brand_img05.png" alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="col-12 col-md-12">
                                    <div className="brand-item">
                                        <img src="assets/img/brand/brand_img06.png" alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>







                    </div>
                </div>
            </div>
            {/* <!-- brand-area-end --> */}
        </>
    )
}

export default BrandPage