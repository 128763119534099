import React from 'react'
import Company from './Company'
import ServicePage from '../../common/ServicePage'
import NewsLetter from '../../common/NewsLetter'

const Portfolio = () => {
  return (
   <>
     <main>

{/* <!-- breadcrumb-area --> */}
<section className="breadcrumb-area breadcrumb-area-two pt-175 ">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-content">
                    <h2 className="title text-white">Portfolio Details</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Portfolio Details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- breadcrumb-area-end --> */}

{/* <!-- project-details-area --> */}
<section className="project-details-area pt-40 pb-120 ">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="project-details-inner">
                    <div className="project-details-img">
                        <img src="assets/img/images/project_details_img.jpg" alt=""/>
                    </div>
                    <div className="project-details-content">
                        <h2 className="title">Creative Agency</h2>
                        <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form have suffered alteration in some form.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="counter-item-wrap-four">
                                <ul className="list-wrap">
                                    <li>
                                        <div className="counter-item-four">
                                            <h2 className="count"><span className="odometer" data-count="15"></span>K+</h2>
                                            <p>Entries in <span>Accunting</span></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="counter-item-four">
                                            <h2 className="count"><span className="odometer" data-count="120"></span>+</h2>
                                            <p>International <span>Programs</span></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="counter-item-four">
                                            <h2 className="count"><span className="odometer" data-count="46"></span>+</h2>
                                            <p>Countries in<span>The World</span></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="counter-item-four">
                                            <h2 className="count"><span className="odometer" data-count="17"></span>+</h2>
                                            <p>Awards <span>Programs</span></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="project-shape-wrap">
        <img src="assets/img/images/services_details_shape.png" alt="" />
    </div>
</section>
{/* <!-- project-details-area-end --> */}

<Company/>
<ServicePage/>
<NewsLetter/>


</main>


   </>
  )
}

export default Portfolio