import React from 'react';


const PricingWorkPage = () => {
  return (
    <>


{/* <!-- work-area --> */}
            <section class="work-area pt-110 pb-120">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="work-img text-center">
                                <img src="assets/img/images/work_img.png" alt=""/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="work-content">
                                <div class="section-title title-style-two white-title mb-30">
                                    {/* <span class="sub-title">Working Steps</span> */}
                                    <h2 class="title  wow fadeInRight">Our Design Process</h2>
                                </div>
                                <div class="work-list">
                                    <ul class="list-wrap">
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/about_icon01.png" alt=""/>
                                            </div>
                                            <div class="content">
                                                <h4 class="title  wow fadeInRight">Requirement Analysis</h4>
                                                <p>Planning</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/about_icon02.png" alt=""/>
                                            </div>
                                            <div class="content">
                                                <h4 class="title  wow fadeInRight">Database Designing</h4>
                                                <p>Conceptual, Logical and Physical Design</p> </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/about_icon03.png" alt=""/>
                                            </div>
                                            <div class="content">
                                                <h4 class="title  wow fadeInRight">Implementation:</h4>
                                                <p>Data Conversion and Loading, Testing</p></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="work-shape-wrap">
                    <img src="assets/img/images/work_shape01.png" alt=""/>
                    <img src="assets/img/images/work_shape02.png" alt="" data-parallax='{"y" : 80 }'/>
                    <img src="assets/img/images/work_shape03.png" alt=""/>
                    <img src="assets/img/images/work_shape04.png" alt=""/>
                    <img src="assets/img/images/work_shape05.png" alt=""/>
                </div>
            </section >
            {/* <!-- work-area-end --> */}

<section className="about-area-two">
                <div className="container">
                    <div className="row align-items-center  ">
                        <div className="col-lg-6 order-0 order-lg-2  ">
                            <div className="about-img-two text-center ">
                          
                            <img src="assets/img/brand/chart.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-two">
                                <div className="section-title white-title title-style-two ">
                                    {/* <span className="sub-title">I'm a Designer</span> */}
                                    <h2 className="title wow fadeInLeft center-title"> Growth Analysis For Company</h2>
                                </div>
                                <p className=' wow fadeInRight'>The best indicators of company growth are a high gross profit growth rate, sales growth, good cash flow, and improved customer retention rate. These rates are used to assess where your business might be lacking.</p>
                                {/* <Link to='/'>
                                    <span className="arrow"><img src="assets/img/icon/right_arrow.svg" fillRule="evenodd" alt="" /></span>
                                    <span className="text">Learn More</span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="about-shape-wrap">
                    <img src="assets/img/images/h2_about_shape01.png" alt="" className="shape-one" data-parallax='{"y" : 100 }' />
                    <img src="assets/img/images/h2_about_shape02.png" alt="" className="shape-two" />
                    <img src="assets/img/images/h2_about_shape03.png" alt="" className="shape-three" />
                </div> */}
            </section>


     
    </>
  );
};

export default PricingWorkPage;
