 import React from 'react'
 
 const DeveloperPage = () => {
   return (
    <>
        
        {/* <!-- developr-area --> */}
            <section className="developr-area pb-120 ">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-9 order-0 order-lg-2">
                            <div className="developr-img">
                                <img src="assets/img/images/developer_img.png" alt=""/>
                                <div className="work-experience-wrap">
                                    <h2 className="count"><span className="odometer" data-count="18"></span>+</h2>
                                    <p>Years Of Experience</p>
                                </div>
                                <div className="project-completed-wrap">
                                    <h2 className="count"><span className="odometer" data-count="5"></span>K+</h2>
                                    <p>Have Faithfully Completed Projects Till Date</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="developr-content">
                                <div className="section-title title-style-two mb-50">
                                    <span className="sub-title">I’m a Developer</span>
                                    <h2 className="title">I Develop Ideas <br/> That Help People</h2>
                                </div>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details-tab-pane" type="button" role="tab" aria-controls="details-tab-pane" aria-selected="true">My Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="awards-tab" data-bs-toggle="tab" data-bs-target="#awards-tab-pane" type="button" role="tab" aria-controls="awards-tab-pane" aria-selected="false">My Awards</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="skils-tab" data-bs-toggle="tab" data-bs-target="#skils-tab-pane" type="button"
                                            role="tab" aria-controls="skils-tab-pane" aria-selected="false">My Skils</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="details-tab-pane" role="tabpanel" aria-labelledby="details-tab" tabIndex="0">
                                        <div className="developer-info-wrap">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-wrap">
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon01.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Name:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon02.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Email:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon03.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Language:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className="list-wrap">
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon04.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Phone No:</span>
                                                                <p>8 (495) 989—20—11</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon05.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Address:</span>
                                                                <p>Ranelagh Place, L3 5UL, England</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon06.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Nationality:</span>
                                                                <p>English, Russian, Frisian</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="awards-tab-pane" role="tabpanel" aria-labelledby="awards-tab" tabIndex="0">
                                        <div className="developer-info-wrap">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-wrap">
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon01.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Name:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon02.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Email:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon03.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Language:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className="list-wrap">
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon04.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Phone No:</span>
                                                                <p>8 (495) 989—20—11</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon05.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Address:</span>
                                                                <p>Ranelagh Place, L3 5UL, England</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon06.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Nationality:</span>
                                                                <p>English, Russian, Frisian</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="skils-tab-pane" role="tabpanel" aria-labelledby="skils-tab" tabIndex="0">
                                        <div className="developer-info-wrap">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-wrap">
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon01.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Name:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon02.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Email:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon03.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Language:</span>
                                                                <p>Jams Robot</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className="list-wrap">
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon04.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Phone No:</span>
                                                                <p>8 (495) 989—20—11</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon05.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Address:</span>
                                                                <p>Ranelagh Place, L3 5UL, England</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon">
                                                                <img src="assets/img/icon/developer_icon06.png" alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <span>Nationality:</span>
                                                                <p>English, Russian, Frisian</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- developr-area-end --> */}
    </>
   )
 }
 
 export default DeveloperPage
 