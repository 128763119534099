import React from 'react'
import ContactDetailsPage from './ContactDetailsPage'
import {Link} from  'react-router-dom'

const ContactUsPAge = () => {
  return (
    <>
         <main>

{/* <!-- breadcrumb-area --> */}
<section className="breadcrumb-area  pt-175 pb-160 " >
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-content">
                    <h2 className="title text-white">Contact Us</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item " aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div className="breadcrumb-shape-wrap-three">
        <img src="assets/img/images/breadcrumb_shape04.png" alt="" className="wow zoomIn" data-wow-delay=".2s" />
        <img src="assets/img/images/breadcrumb_shape05.png" alt="" className="wow zoomIn" data-wow-delay=".2s" />
        <img src="assets/img/images/breadcrumb_shape06.png" alt="" className="wow zoomIn" data-wow-delay=".2s" />
    </div>
</section>
{/* <!-- breadcrumb-area-end -->*/}
<ContactDetailsPage/>


</main>
    </>
  )
}

export default ContactUsPAge
