import React from 'react'

const BlogDescriprion = () => {
  return (
   <>
       {/* <!-- blog-area --> */}
            <section className="inner-blog-area pb-120 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-70">
                            <div className="inner-blog-item">
                                <div className="inner-blog-thumb">
                                    <a href="blog-details.html"><img src="assets/img/blog/inner_blog_img01.jpg" alt="" /></a>
                                </div>
                                <div className="inner-blog-content">
                                    <div className="blog-meta-two">
                                        <ul className="list-wrap">
                                            <li className="tag"><a href="blog.html">Sector</a></li>
                                            <li><i className="fal fa-clock"></i>5 Min</li>
                                            <li><i className="fal fa-calendar"></i>March 17, 2022</li>
                                            <li>By <a href="blog.html">Victor Pacheco</a></li>
                                        </ul>
                                    </div>
                                    <h2 className="title"><a href="blog-details.html">How to Post a classNameified Ad Online or in Newspapers</a></h2>
                                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec.</p>
                                    <a href="blog-details.html" className="rade-more-btn">Read More</a>
                                </div>
                            </div>
                            <div className="inner-blog-item">
                                <div className="inner-blog-thumb">
                                    <a href="blog-details.html"><img src="assets/img/blog/inner_blog_img02.jpg" alt="" /></a>
                                </div>
                                <div className="inner-blog-content">
                                    <div className="blog-meta-two">
                                        <ul className="list-wrap">
                                            <li className="tag"><a href="blog.html">Sector</a></li>
                                            <li><i className="fal fa-clock"></i>5 Min</li>
                                            <li><i className="fal fa-calendar"></i>March 17, 2022</li>
                                            <li>By <a href="blog.html">Victor Pacheco</a></li>
                                        </ul>
                                    </div>
                                    <h2 className="title"><a href="blog-details.html">How to Post a classNameified Ad Online or in Newspapers</a></h2>
                                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec.</p>
                                    <a href="blog-details.html" className="rade-more-btn">Read More</a>
                                </div>
                            </div>
                            <div className="inner-blog-item">
                                <div className="inner-blog-thumb">
                                    <a href="blog-details.html"><img src="assets/img/blog/inner_blog_img03.jpg" alt="" /></a>
                                </div>
                                <div className="inner-blog-content">
                                    <div className="blog-meta-two">
                                        <ul className="list-wrap">
                                            <li className="tag"><a href="blog.html">Sector</a></li>
                                            <li><i className="fal fa-clock"></i>5 Min</li>
                                            <li><i className="fal fa-calendar"></i>March 17, 2022</li>
                                            <li>By <a href="blog.html">Victor Pacheco</a></li>
                                        </ul>
                                    </div>
                                    <h2 className="title"><a href="blog-details.html">How to Post a classNameified Ad Online or in Newspapers</a></h2>
                                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec.</p>
                                    <a href="blog-details.html" className="rade-more-btn">Read More</a>
                                </div>
                            </div>
                            <div className="pagination-wrap">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination list-wrap">
                                        <li className="page-item"><a className="page-link" href="# ">1</a></li>
                                        <li className="page-item active"><a className="page-link" href=" #">2</a></li>
                                        <li className="page-item"><a className="page-link" href="# ">3</a></li>
                                        <li className="page-item"><a className="page-link" href="# ">4</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-30">
                            <aside className="blog-sidebar">
                                <div className="widget">
                                    <div className="rc-post-wrap">
                                        <div className="rc-post-item">
                                            <div className="thumb">
                                                <a href="blog-dtails.html"><img src="assets/img/blog/rc_post_img01.jpg" alt="" /></a>
                                            </div>
                                            <div className="content">
                                                <div className="blog-meta-two">
                                                    <ul className="list-wrap">
                                                        <li className="tag"><a href="blog.html">Sector</a></li>
                                                        <li><i className="fal fa-clock"></i>5 Min</li>
                                                    </ul>
                                                </div>
                                                <h4 className="title"><a href="blog-details.html">Skello launches electronic signature</a></h4>
                                            </div>
                                        </div>
                                        <div className="rc-post-item">
                                            <div className="thumb">
                                                <a href="blog-dtails.html"><img src="assets/img/blog/rc_post_img02.jpg" alt="" /></a>
                                            </div>
                                            <div className="content">
                                                <div className="blog-meta-two">
                                                    <ul className="list-wrap">
                                                        <li className="tag"><a href="blog.html">Our Team</a></li>
                                                        <li><i className="fal fa-clock"></i>5 Min</li>
                                                    </ul>
                                                </div>
                                                <h4 className="title"><a href="blog-details.html">Skello launches electronic signature</a></h4>
                                            </div>
                                        </div>
                                        <div className="rc-post-item">
                                            <div className="thumb">
                                                <a href="blog-dtails.html"><img src="assets/img/blog/rc_post_img03.jpg" alt="" /></a>
                                            </div>
                                            <div className="content">
                                                <div className="blog-meta-two">
                                                    <ul className="list-wrap">
                                                        <li className="tag"><a href="blog.html">Solution</a></li>
                                                        <li><i className="fal fa-clock"></i>5 Min</li>
                                                    </ul>
                                                </div>
                                                <h4 className="title"><a href="blog-details.html">Skello launches electronic signature</a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h2 className="widget-title">Categories</h2>
                                    <div className="blog-cat-list">
                                        <ul className="list-wrap">
                                            <li><a  href="# ">Travel <span>(3)</span></a></li>
                                            <li><a  href="# ">Sport<span>(7)</span></a></li>
                                            <li><a  href="# ">Education<span>(12)</span></a></li>
                                            <li><a  href="# ">Business<span>(5)</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h2 className="widget-title">Popular posts</h2>
                                    <div className="popular-post-list">
                                        <ul className="list-wrap">
                                            <li>
                                                <div className="popular-post-item big-post mb-40">
                                                    <div className="thumb">
                                                        <a href="blog-details.html"><img src="assets/img/blog/pu_post_img01.jpg" alt=" " /></a>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title"><a href="blog-details.html">Five Quick Jaw Droppping Tips <br /> to Sell Your Vehicle Online</a></h5>
                                                        <span>March 17, 2023</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="popular-post-item mb-40">
                                                    <div className="thumb">
                                                        <a href="blog-details.html"><img src="assets/img/blog/pu_post_img02.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title"><a href="blog-details.html">Purchase advertising...</a></h5>
                                                        <span>March 17, 2023</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="popular-post-item mb-40">
                                                    <div className="thumb">
                                                        <a href="blog-details.html"><img src="assets/img/blog/pu_post_img03.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title"><a href="blog-details.html">Purchase advertising...</a></h5>
                                                        <span>March 17, 2023</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="popular-post-item">
                                                    <div className="thumb">
                                                        <a href="blog-details.html"><img src="assets/img/blog/pu_post_img04.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title"><a href="blog-details.html">Purchase advertising...</a></h5>
                                                        <span>March 17, 2023</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="popular-post-item">
                                                    <div className="thumb">
                                                        <a href="blog-details.html"><img src="assets/img/blog/pu_post_img05.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title"><a href="blog-details.html">Purchase advertising...</a></h5>
                                                        <span>March 17, 2023</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h2 className="widget-title">Tags</h2>
                                    <div className="tag-list">
                                        <ul className="list-wrap">
                                            <li><a href=" #">Design</a></li>
                                            <li><a href=" #">Fashion</a></li>
                                            <li><a href=" #">Looks</a></li>
                                            <li><a href=" #">Men</a></li>
                                            <li><a href=" #">Music</a></li>
                                            <li><a href=" #">Style</a></li>
                                            <li><a href=" #">Women</a></li>
                                            <li><a href=" #">Fashion</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- blog-area-end --> */}
   </>
  )
}

export default BlogDescriprion
