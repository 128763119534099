import React from 'react'

const ContactDetailsPage = () => {
    return (
        <>
            {/* <!-- contact-area --> */}
            <section className="inner-contact-area pt-100 pb-100" style={{ background: '#e1e4e5' }}>
                <div className="container" >
                    <div className="inner-contact-wrap">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10">
                                <div className="section-title title-style-two mb-50">
                                    <h2 className="title  wow zoomIn center-title">Have a <span>Cool Project?</span> Get in touch!</h2>
                                </div>
                                <div className="inner-contact-form-wrap">
                                    <form action="#">
                                        <div className="form-grp">
                                            <label htmlFor="name"><i className="fas fa-user"></i></label>
                                            <input type="text" id="name" placeholder="Name" />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="phone"><i className="fas fa-phone"></i></label>
                                            <input type="text" id="phone" placeholder="Phone" />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="email"><i className="fas fa-envelope"></i></label>
                                            <input type="email" id="email" placeholder="Email Address" />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="subject"><i className="fas fa-book-alt"></i></label>
                                            <input type="text" id="subject" placeholder="Subject" />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="comment"><i className="fas fa-user-edit"></i></label>
                                            <textarea name="comment" id="comment" placeholder="How can we help you? Feel free to get in touch!"></textarea>
                                        </div>
                                        <button type="button " className="btn-data btn-primary ">Send Message <span></span></button>
                                    </form>
                                </div>
                                <div id="contact-map">
                                    {/* 
                                    <iframe
                                        title="Map"
                                       
                                        allowFullScreen=""
                                        loading="lazy"
                                        
                                    ></iframe> */}

                                    <iframe
                                        title="Map"
                                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d13859.364531200865!2d76.98662581963599!3d29.724360796390755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%22382%20Alpha%20International%20City%2C%20Karna!5e0!3m2!1sen!2sin!4v1679308718928!5m2!1sen!2sin"
                                              allowFullScreen=""
                                        loading="lazy">
                                        </iframe>

                                </div>
                                <div className="inner-contact-info">
                                    <ul className="list-wrap">
                                        <li>
                                            <div className="contact-info-item  wow fadeInRight">
                                                <div className="icon">
                                                    <img src="assets/img/icon/loction_icon03.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Address</h6>
                                                    <p>382 Alpha International City, Karnal</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-info-item  wow fadeInRight">
                                                <div className="icon">
                                                    <img src="assets/img/icon/mail_icon03.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Email</h6>
                                                    <a href="mailto:your@email.com">support@datogix.com</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-info-item  wow fadeInRight">
                                                <div className="icon">
                                                    <img src="assets/img/icon/phone_icon03.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Phone</h6>
                                                    <a href="tel:0123456789"></a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  <!-- contact-area-end --> */}

        </>
    )
}

export default ContactDetailsPage
