import React from 'react'

const ServicePage = () => {
  return (
   <>
     {/* <!-- services-area --> */}
            <section className="inner-services-area-two pt-110 pb-90 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8">
                            <div className="section-title text-center mb-65">
                                <h2 className="title text-white">We Create Creative Designs For Every Web Page</h2>
                                <p className='text_white-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="inner-services-wrap-two">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                <div className="services-item-five">
                                    <div className="services-icon-five">
                                        <img src="assets/img/icon/inner_two_services_icon01.png" alt="" />
                                    </div>
                                    <div className="services-content-five">
                                        <h2 className="title text-white"><a href="services-details.html">User Interaction <span>Design</span></a></h2>
                                        <p className='text-white-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                <div className="services-item-five">
                                    <div className="services-icon-five">
                                        <img src="assets/img/icon/inner_two_services_icon02.png" alt="" />
                                    </div>
                                    <div className="services-content-five">
                                        <h2 className="title  text-white"><a href="services-details.html">Production <span>Design</span></a></h2>
                                        <p className='text-white-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                <div className="services-item-five">
                                    <div className="services-icon-five">
                                        <img src="assets/img/icon/inner_two_services_icon03.png" alt="" />
                                    </div>
                                    <div className="services-content-five">
                                        <h2 className="title  text-white"><a href="services-details.html">Branding <span>Design</span></a></h2>
                                        <p className='text-white-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                <div className="services-item-five">
                                    <div className="services-icon-five">
                                        <img src="assets/img/icon/inner_two_services_icon04.png" alt="" />
                                    </div>
                                    <div className="services-content-five">
                                        <h2 className="title  text-white"><a href="services-details.html">Product <span>Design</span></a></h2>
                                        <p className='text-white-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- services-area-end --> */}

   </>
  )
}

export default ServicePage
