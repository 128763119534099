import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
  <>
   <footer>
            <div className="footer-area-two">
                <div className="container">
                    <div className="footer-top-two">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="footer-content-two text-center">
                                    <div className="logo">
                                        <Link to='/'><img src="assets/img/logo/logo.png" alt="" height={50} /></Link>
                                    </div>
                                    <p>We are passionate about helping our clients succeed and achieve their goals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom-two">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="copyright-text">
                                    <p>©2023 DatoGix</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="footer-social-two">
                                    <ul className="list-wrap">
                                        <li className="title">Follow us</li>
                                        <li><a href="# "><img src="assets/img/icon/footer_icon01.png" alt="" /></a></li>
                                        <li><a href="# "><img src="assets/img/icon/footer_icon02.png" alt="" /></a></li>
                                        <li><a href="# "><img src="assets/img/icon/footer_icon03.png" alt="" /></a></li>
                                        <li><a href="# "><img src="assets/img/icon/footer_icon04.png" alt="" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  </>
  )
}

export default Footer