
import React from 'react';
import { Link, useLocation } from 'react-router-dom'
const Navbar = () => {
    const location = useLocation();

    return (
        <>




            {/* <!-- Navbar --> */}

            <div className="">
                <nav id="sticky-header" className="navbar navbar-expand-lg  p-4 menu-area menu-style-two  ">

                    <div className="container">

                        <div className="logo">
                            <Link to='/'><img src="assets/img/logo/logo.png" alt="Logo" height={40} /></Link>
                        </div>


                        <button
                            className="custom-toggler navbar-toggler"
                            type="button"
                            data-mdb-toggle="collapse"
                            data-mdb-target="#navbarButtonsExample"
                            aria-controls="navbarButtonsExample"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="custom-toggler navbar-toggler-icon "></i>
                        </button>


                        <div className="collapse navbar-collapse" id="navbarButtonsExample" >

                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">

                                </li>
                            </ul>


                            <div className="d-flex align-items-center">

                                <ul className='navbar-nav mb-2 mb-lg-0'>
                                    <li className='menu-item  '><Link to='/' className={` menu-item  ${location.pathname === '/' ? "active-menu" : ""}`}> <span data-mdb-target="#navbarButtonsExample" data-mdb-toggle="collapse">Home</span> </Link></li>

                                    <li className=' menu-item'><Link to='/about' className={`menu-item ${location.pathname === '/about' ? "active-menu" : ""}`}> <span data-mdb-target="#navbarButtonsExample" data-mdb-toggle="collapse">About Us</span></Link></li>
                                    <li className='menu-item '><Link to='/service' className={`menu-item ${location.pathname === '/service' ? "active-menu" : ""}`}><span data-mdb-target="#navbarButtonsExample" data-mdb-toggle="collapse">Service</span></Link></li>
                                    <li className='menu-item mobile-contact'>
                                        <Link to='/contact' className={`menu-item ${location.pathname === '/contact' ? "active-menu" : ""}`}><span data-mdb-target="#navbarButtonsExample" data-mdb-toggle="collapse">Contact</span></Link>
                                    </li>
                                    <li className="menu-item desktop-contact"><Link to='/contact' className="btn-data mx-4 btn-primary">Contact <span></span></Link></li>

                                </ul>

                            </div>
                        </div>

                    </div>

                </nav>
            </div>





        </>
    )
}

export default Navbar