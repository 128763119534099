import React from 'react'

const NewsLetter = () => {
  return (
    <>
         {/* <!-- newsletter-area --> */}
            <section className="newsletter-area newsletter-area-three pb-120 " >
                <div className="container">
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-6">
                            <div className="section-title text-center mb-80">
                                <span className="sub-title ">Get update</span>
                                <h2 className="title text-white">Get latest updates <br/> and dealsi</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="newsletter-form">
                                <form action="#">
                                    <input type="email" placeholder="Enter your email address"/>
                                    <button type="submit" className="btn">Subscribe <span></span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="newsletter-shape-wrap">
                    <img src="assets/img/images/newsletter_bg_shape.png" alt="" className="bg-shape" />
                    <img src="assets/img/images/newsletter_shape01.png" alt="" className="shape-one" />
                    <img src="assets/img/images/newsletter_shape02.png" alt="" className="shape-two" />
                    <img src="assets/img/images/newsletter_shape03.png" alt="" className="shape-three" />
                    <img src="assets/img/images/newsletter_shape04.png" alt="" className="shape-four" />
                    <img src="assets/img/images/newsletter_shape05.png" alt="" className="shape-five" />
                    <img src="assets/img/images/newsletter_shape06.png" alt="" className="shape-six" />
                </div>
            </section>
            {/* <!-- newsletter-area-end --></img> */}
    </>
  )
}

export default NewsLetter