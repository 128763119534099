import React from 'react'
import {Link} from 'react-router-dom'
const Contact = () => {
  return (
    <>

        {/* <!-- blog-area --> */}
        <section className="blog-area-two pt-110 pb-90">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title title-style-two white-title text-center mb-60">
                                    <span className="sub-title  wow bounceInDown">My Blog</span>
                                    <h2 className="title wow bounceInUp">Recent blog post</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="blog-item-two">
                                    <div className="blog-thumb-two">
                                        <Link to='/'><img src="assets/img/blog/data-anlysis.png" alt="" height={220} width={400}/></Link>
                                        <h5 className="date  wow fadeInLeft">27 <span>Nov</span></h5>
                                    </div>
                                    <div className="blog-content-two">
                                        <a href="blog.html" className="tag"></a>
                                        <h2 className="title"><a href="blog-details.html">Foreign Data Wrapper in Postgres</a></h2>
                                        <p>Foreign data is accessed with help from a foreign data wrapper. A foreign data wrapper is a library that can communicate with an external data source, hiding the details of connecting to the data source and obtaining data from it. </p></div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="blog-item-two">
                                    <div className="blog-thumb-two">
                                        <Link to='/'><img src="assets/img/blog/ai.png" alt="" height={220} width={400}/></Link>
                                        <h5 className="date  wow fadeInLeft">27 <span>Nov</span></h5>
                                    </div>
                                    <div className="blog-content-two">
                                        <a href="blog.html" className="tag"></a>
                                        <h2 className="title"><a href="blog-details.html">Data De-Duplication</a></h2>
                                        <p>The data deduplication process is based on eliminating redundancy in data.Data deduplication is usually represented in ratios; for instance, a 10:1 reduction ratio means that you’re reducing the storage capacity requirements by 90%.</p></div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="blog-item-two">
                                    <div className="blog-thumb-two">
                                        <Link to='/'><img src="assets/img/blog/data-visualization.png"height={220} width={400} alt="" /></Link>
                                        <h5 className="date  wow fadeInLeft">27 <span>Nov</span></h5>
                                    </div>
                                    <div className="blog-content-two">
                                        <a href="blog.html" className="tag"></a>
                                        <h2 className="title"><a href="blog-details.html"> Query 
Optimization Techniques</a></h2>
                                        <p>Query optimization is a process of defining the most efficient and optimal way and techniques that can be used to improve query performance based on rational use of system resources and performance metrics. </p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- blog-area-end --> */}


                {/* <!-- contact-area --> */}
                <section className="contact-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title title-style-two white-title text-center mb-75">
                                    <span className="sub-title wow bounceInUp">Contact</span>
                                    <h2 className="title wow fadeInLeft">We Want to Hear from You</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 order-0 order-lg-2">
                                <div className="contact-info-wrap">
                                    <ul className="list-wrap">
                                        <li>
                                            <div className="icon  wow bounceInUp">
                                                <img src="assets/img/icon/loction_icon02.png" alt="" />
                                            </div>
                                            <div className="content  wow fadeInRight">
                                                <h4 className="title">Address</h4>
                                                <span>382 Alpha International City, Karnal</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon wow bounceInUp">
                                                <img src="assets/img/icon/mail_icon02.png" alt="" />
                                            </div>
                                            <div className="content  wow fadeInRight">
                                                <h4 className="title">Email</h4>
                                                <a href="mailto:your@email.com" >support@datogix.com</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon wow bounceInUp">
                                                <img src="assets/img/icon/phone_icon02.png" alt="" />
                                            </div>
                                            <div className="content  wow fadeInRight">
                                                <h4 className="title">Phone</h4>
                                                <a href="tel:0123456789"></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="contact-form-wrap">
                                    <form action=" ">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input type="text" placeholder="Your Number" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input type="text" placeholder="Subject" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-grp">
                                            <textarea name="message" placeholder="Write your message here"></textarea>
                                        </div>
                                        <button type="button" className="btn-data btn-primary wow fadeInRight">Submit Now <span></span></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- contact-area-end --> */}
    </>
  )
}

export default Contact