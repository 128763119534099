import React from 'react'

const Consutation = () => {
    return (
        <>
      
            {/* <!-- consultation-area --> */}
            <section className="consultation-area consultation-area-two pt-120 pb-120 white-background ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="consultation-content">
                                <div className="section-title mb-25">
                                    {/* <span className="sub-title">95% Accuracy</span> */}
                                    <h2 className="title wow fadeInLeft center-title">Best Database Consultation Services</h2>
                                </div>
                               <p>When choosing a database consultation service, it's important to consider factors such as expertise, reputation, and cost. You may also want to look for a provider that offers a range of services, including database design, implementation, and management, as well as consulting services to help you optimize your database performance over time. </p>
                      
                        </div>
                       
                    </div>
                    <div className="col-lg-5">
                        <div className="consultation-form-wrap">
                            <h4 className="title">Free Consultation</h4>
                            <form action="#">
                                <div className="form-grp">
                                    <input type="text" placeholder="Name" />
                                </div>
                                <div className="form-grp">
                                    <input type="email" placeholder="Email Address" />
                                </div>
                                <div className="form-grp">
                                    <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="form-grp">
                                    <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                        <option value="">Subject</option>
                                        <option>Subject One</option>
                                        <option>Subject Two</option>
                                        <option>Subject Three</option>
                                        <option>Subject Four</option>
                                    </select>
                                </div>
                                <button className="btn-data" type="button">Consultation</button>
                            </form>
                        </div>
                    </div>
                </div>
                </div>

                <div className="consultation-shape-wrap">
                    <img src="assets/img/images/consultation_shape01.png" alt="" className="shape-one ribbonRotate" />
                    <img src="assets/img/images/consultation_shape02.png" alt="" className="shape-two float-bob-x" />
                </div>
            </section>

            {/* <!-- consultation-area-end --> */}

        </>
    )
}

export default Consutation