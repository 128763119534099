import React from 'react'

const TeamAreaPage = () => {
  return (
    <>
    {/* <!-- team-area --> */}
            <section className="team-area-two pt-110 pb-100 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="team-item">
                                <div className="team-thumb">
                                    <a href="team-details.html"><img src="assets/img/team/inner_team01.jpg" alt="" /></a>
                                </div>
                                <div className="team-content">
                                    <h2 className="title"><a href="team-details.html">Alena Rosser</a></h2>
                                    <span>CEO Kawasaki Inc.</span>
                                    <div className="team-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href=" #"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="# "><i className="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="team-item">
                                <div className="team-thumb">
                                    <a href="team-details.html"><img src="assets/img/team/inner_team02.jpg" alt="" /></a>
                                </div>
                                <div className="team-content">
                                    <h2 className="title"><a href="team-details.html">Tiana Dokidis</a></h2>
                                    <span>CEO Kawasaki Inc.</span>
                                    <div className="team-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="# "><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="# "><i className="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="team-item">
                                <div className="team-thumb">
                                    <a href="team-details.html"><img src="assets/img/team/inner_team03.jpg" alt="" /></a>
                                </div>
                                <div className="team-content">
                                    <h2 className="title"><a href="team-details.html">Ryan Vetrovs</a></h2>
                                    <span>CEO Kawasaki Inc.</span>
                                    <div className="team-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="# "><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href=" #"><i className="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="team-item">
                                <div className="team-thumb">
                                    <a href="team-details.html"><img src="assets/img/team/inner_team04.jpg" alt="" /></a>
                                </div>
                                <div className="team-content">
                                    <h2 className="title"><a href="team-details.html">Emerson Saris</a></h2>
                                    <span>CEO Kawasaki Inc.</span>
                                    <div className="team-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="# "><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="# "><i className="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="team-item">
                                <div className="team-thumb">
                                    <a href="team-details.html"><img src="assets/img/team/inner_team05.jpg" alt="" /></a>
                                </div>
                                <div className="team-content">
                                    <h2 className="title"><a href="team-details.html">Lindsey Schleifer</a></h2>
                                    <span>CEO Kawasaki Inc.</span>
                                    <div className="team-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="# "><i className="fab fa-twitter"></i></a></li>
                                            <li><a href=" #"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="# " ><i className="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="team-item">
                                <div className="team-thumb">
                                    <a href="team-details.html"><img src="assets/img/team/inner_team06.jpg" alt="" /></a>
                                </div>
                                <div className="team-content">
                                    <h2 className="title"><a href="team-details.html">Dulce Dokidis</a></h2>
                                    <span>CEO Kawasaki Inc.</span>
                                    <div className="team-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="# "><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="# "><i className="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- team-area-end --> */}


    </>
  )
}

export default TeamAreaPage