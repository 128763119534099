import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
SwiperCore.use([Autoplay]);

const DatabasePage = () => {
    const breakpoints = {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,

        },
        768: {
            slidesPerView: 4,
            spaceBetween: 20,
        },

    };
    return (
        <>
            <div className="brand-area">
                <div className="container">
                    <div className="row brand-active">

                        <Swiper
                            breakpoints={breakpoints}
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={-10}
                            slidesPerView={5}
                            autoplay={{ delay: 2000 }}
                            onSwiper={(swiper) => console.log()}
                            onSlideChange={() => console.log()}
                        >
                            <SwiperSlide>
                                <div className='mx-3'>
                                    <div className="col-sm-12 col-md-12 ">
                                        <div className="brand-item">
                                            <img src="assets/img/database/oracle.png" alt="" height={80} />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='mx-3'>
                                    <div className="col-sm-12 col-md-12 ">
                                        <div className="brand-item">
                                            <img src="assets/img/database/Mongodb.png" alt="" height={100} />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='mx-3'>
                                    <div className="col-sm-12 col-md-12 ">
                                        <div className="brand-item">
                                            <img src="assets/img/database/mysqldb.png" alt="" height={80} />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='mx-3'>
                                    <div className="col-sm-12 col-md-12 ">
                                        <div className="brand-item">
                                            <img src="assets/img/database/sql_server.png" alt="" height={100} />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='mx-3'>
                                    <div className="col-sm-12 col-md-12 ">
                                        <div className="brand-item">
                                            <img src="assets/img/brand/postgrasql.png" alt="" height={100} />

                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>




                            <SwiperSlide>
                                <div className='mx-3'>
                                    <div className="col-sm-12 col-md-12 ">
                                        <div className="brand-item">
                                            <img src="assets/img/database/server.png" alt="" height={90} />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>



                        </Swiper>






                    </div>
                </div>
            </div>
        </>
    )
}

export default DatabasePage
