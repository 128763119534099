import React from 'react'
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

const ProjectPage = () => {
  return (
    <>
       {/* <!-- project-area --> */}
            <section className="inner-projcet-area-two pt-140 pb-125">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="section-title title-style-two mb-70">
                                <span className="sub-title">My Latest Project</span>
                                <h2 className="title">Our Best Professional <br/> UI/UX Design</h2>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="inner-project-nav"></div>
                        </div>
                    </div>
                </div>
                <div className="container-fulid p-0">
                    <div className="inner-projcet-wrap-two">
                        <div className="row g-0 inner-project-active">


                        <Swiper
                         modules={[ Pagination]}
                         pagination={{ clickable: true }}
                            spaceBetween={0}
                            slidesPerView={4}
                            
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log()}
                        >

                    <SwiperSlide>
                    <div className="col-lg-12">
                                <div className="inner-project-item horizoital-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project01.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                                <div className="inner-project-item vertical-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project02.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>
                    </SwiperSlide>


                    <SwiperSlide>
                    <div className="col-lg-12">
                                <div className="inner-project-item horizoital-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project04.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                                <div className="inner-project-item vertical-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project03.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12">
                                <div className="inner-project-item horizoital-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project05.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                                <div className="inner-project-item vertical-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project06.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>
                    </SwiperSlide>


                    <SwiperSlide>
                    <div className="col-lg-12">
                                <div className="inner-project-item horizoital-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project08.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                                <div className="inner-project-item vertical-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project07.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12">
                                <div className="inner-project-item horizoital-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project09.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                                <div className="inner-project-item vertical-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project10.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>
                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="col-lg-12">
                                <div className="inner-project-item horizoital-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project08.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                                <div className="inner-project-item vertical-item">
                                    <div className="inner-project-thumb">
                                        <a href="project-details.html"><img src="assets/img/project/inner_two_project07.jpg" alt="" /></a>
                                    </div>
                                    <div className="inner-project-content">
                                        <h3 className="title"><a href="project-details.html">Motion Design</a></h3>
                                        <p>Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>

                    </SwiperSlide>



                        </Swiper>


                           
                           
                          
                            
                            
                           
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- project-area-end --> */}

    </>
  )
}

export default ProjectPage