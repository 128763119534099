import React from 'react'
import Work from './Work'
import {Link} from 'react-router-dom'

function Service() {
    return (
        <>
            <section className="breadcrumb-area breadcrumb-area-two pt-175 ">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="title text-white wow bounceInDown">Service</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                     
                                        <li className="breadcrumb-item " aria-current="page">Service Details</li>
                              </ol>
                                </nav>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="breadcrumb-shape-wrap">
                   
                   <img src="assets/img/Service/services_icon.png" alt=""  height={100}/>
                   <img src="assets/img/Service/piechart.png" alt=""  height={100}  />
               </div>
            </section>
         
         
            <div className="services-details-area " >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="services-details-img text-center wow zoomIn">
                                <img src="assets/img/images/services_details_img.png" alt="" />
                            </div>
                            <div className="services-details-content text-center wow animate__animated animate__slideInRight">
    <p className='text-white f-200 wow'><strong>service involves analyzing large data sets to identify patterns, trends, and insights that can be used to inform business decisions.</strong></p>
</div>

                        </div>
                    </div>
                </div>
                <div className="services-details-shape">
                    <img src="assets/img/images/services_details_shape.png" alt="" />
                </div>
            </div>

            <section className="history-area mt-5 bg-light ">
                <div className="container">
                <h2 className='text-center text-muted mb-5 wow fadeInRight'>We Offer A Wide Range Of Services And Provide Complete Client Satisfication</h2>
                    <div className="history-inner  ">
                        <div className="history-img">
                            <img src="assets/img/Service/consult-transformed.jpeg" alt="" />
                        </div>
                        <div className="row g-0 justify-content-end">
                            <div className="col-lg-6">
                                <div className="history-content  wow zoomIn ">
                                    <h2 className="title">Data consultation</h2>
                                    <p>They evaluate processes and technology and create detailed reports. Data consultants research data solutions and recommend the best training, techniques, technology, and strategies. They sometimes train employees to be more efficient in their use of data systems and technology.</p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="history-inner mt-5">
                      
                        <div className="row g-0 justify-content-end">
                            <div className="col-lg-6">
                                <div className="history-content wow zoomIn">
                                    <h2 className="title">Data analysis</h2>
                                    <p>Data analytics is becoming a more integral part of the business world in all aspects. From a financial side, analytics can tell a business how they are doing compared to their competition through accessible data.It can help IT companies understand trends involving operations and make decisions to make their services optimal to all of their clients. Until recently, analytics were being used after the fact</p>

                                </div>
                            </div>
                           
                            <div className="col-lg-6">
                            <div className="history-img  ">
                            <img src="assets/img/Service/data-analysis.png" alt="" />
                        </div>
                            
                            </div>
                        </div>
                    </div>

                    <div className="history-inner mt-5">
                        <div className="history-img">
                            <img src="assets/img/Service/dat-development.png" alt="" />
                        </div>
                        <div className="row g-0 justify-content-end">
                            <div className="col-lg-6">
                                <div className="history-content  wow zoomIn">
                                    <h2 className="title">Data development</h2>
                                    <p>Data development must ensure that data in the data set is well defined and standardised so
                                        that it can be compared independent of the organisation, system or tool that captures the
                                        data.
                                        Data development must not be limited by the capability of any particular system.Data must be able to be collected as a by-product of service delivery or administrative
                                        practice. The data must be relevant and meaningful to those collecting the data and be of
                                        benefit to service providers. </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="history-inner mt-5">
                      
                      <div className="row g-0 justify-content-end">
                          <div className="col-lg-6">
                              <div className="history-content  wow zoomIn">
                                  <h2 className="title">Data optimization</h2>
                                  <p>Data optimization is the process by which organizations extract, analyze, and store data for maximum efficiency. There are a wide variety of data optimization tools that you can use to optimize your data, including automation solutions. Finding the right data optimization techniques for your specific business needs is key to achieving greater efficiency with your data. which is why you may need to experiment with different tools and techniques to determine what works best for your unique operations.</p>

                              </div>
                          </div>
                         
                          <div className="col-lg-6">
                          <div className="history-img">
                          <img src="assets/img/Service/optimizations-transformed.jpeg" alt="" />
                      </div>
                          
                          </div>
                      </div>
                  </div>


                </div>
               



             <div className='container mt-5'>
                <div className='row'>
                <div className='col-md-4'>
                <div class="testimonial-item-five service-three zoom-effect  wow fadeInRight mt-5">

                                    <div className="testimonial-info">
                                        <div className="thumb">
                                            <img src="assets/img/Service/database.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h2 className="title">Query Optimization</h2>
                                          
                                        </div>
                                    </div>
                                    <div className="testimonial-content-five">
                                       <p className='text-secondary'>A single query can be executed through different algorithms or re-written in different forms and structures.</p>
                                    </div>
                                </div>
                    </div>
                    <div className='col-md-4'>
                    <div className="testimonial-item-five service-three zoom-effect wow zoomIn mt-5">
                                    <div className="testimonial-info">
                                        <div className="thumb">
                                            <img src="assets/img/Service/python-scripting.jpg" alt="" />
                                        </div>
                                        <div className="content">
                                            <h2 className="title">Python scripting</h2>
                                        
                                        </div>
                                    </div>
                                    <div className="testimonial-content-five">
                                      <p className='text-secondary'> A Python script is best defined as a Python file that contains commands in logical order.anything—when it's run through the Python interpreter.</p>
                                    </div>
                                </div>
                    </div>
                
                    <div className='col-md-4'>
                    <div className="testimonial-item-five service-three zoom-effect wow fadeInRight mt-5">
                                    <div className="testimonial-info">
                                        <div className="thumb">
                                            <img src="assets/img/Service/scraping.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h2 className="title">Python scraping</h2>
                                          
                                        </div>
                                    </div>
                                    <div className="testimonial-content-five">
                                    <p className='text-secondary'>Web scraping is the process of collecting the Web and the Python community has come up with some pretty powerful web scraping tools.</p>
                                         </div>
                                </div>
                    </div>
                </div>
             </div>
            
            </section>

            <Work/>
        </>
    )
}

export default Service