import React from 'react'
import FaqPage from '../../common/FaqPage'
import ToolPage from '../../common/ToolPage'
import BrandPage from '../../home/BrandPage'
import ProjectPage from './ProjectPage'
import TestimonialPage from './TestimonialPage'
import NewsLetter from '../../common/NewsLetter'
const TeamDetail = () => {
    return (
        <>

            {/* <!-- main-area --> */}
            <main>

                {/* <!-- breadcrumb-area --> */}
                <section className="breadcrumb-area about-me-breadcrumb  pt-175 pb-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title text-white">Team Details</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Team Details</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-shape-wrap">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
                    </div>
                </section>
                {/* <!-- breadcrumb-area-end --> */}

                {/* <!-- developr-area --> */}
                <section className="developr-area-two  pb-120">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-9 order-0 order-lg-2">
                                <div className="developr-img">
                                    <img src="assets/img/images/developer_img.png" alt="" />
                                    <div className="work-experience-wrap">
                                        <h2 className="count"><span className="odometer" data-count="18"></span>+</h2>
                                        <p>Years Of Experience</p>
                                    </div>
                                    <div className="project-completed-wrap">
                                        <h2 className="count"><span className="odometer" data-count="5"></span>K+</h2>
                                        <p>Have Faithfully Completed Projects Till Date</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="developr-content-two">
                                    <div className="section-title title-style-two mb-20">
                                        <span className="sub-title">What We Do</span>
                                        <h2 className="title">We design and develop web and mobile applications for our clients worldwide</h2>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer,</p>
                                    <div className="developr-social">
                                        <ul className="list-wrap">
                                            <li><a href="# "><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="# "><i className="fab fa-facebook-square"></i></a></li>
                                            <li><a href="# "><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="# "><i className="fab fa-pinterest-p"></i></a></li>
                                            <li><a href="# "><i className="fab fa-vimeo-v"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- developr-area-end --> */}

                <ToolPage />

                <FaqPage />

                {/* <!-- client-area --> */}
                <section className="client-area pt-120 ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="section-title title-style-two text-center mb-55">
                                    <span className="sub-title">My Clients</span>
                                    <h2 className="title">I Work With Over 175+ Happy Client</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row brand-active">
                            <BrandPage />
                        </div>
                    </div>
                </section>
                {/* <!-- client-area-end --> */}

                <ProjectPage/>

                <TestimonialPage/>

<div className='mt-5'>
<NewsLetter/>
            
</div>

                 
            </main>
        </>
    )
}

export default TeamDetail