import React from 'react'

import BrandPage from '../../home/BrandPage'
import NewsLetterPage from '../../common/NewsLetterPage'
import TeamAreaPage from './TeamAreaPage'

const TeamPage = () => {
  return (
   <>
   {/* <!-- main-area --> */}
        <main >

            {/* <!-- breadcrumb-area --> */}
            <section className="breadcrumb-area breadcrumb-area-two  pt-175">
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="title text-white">Our Team Members</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Team</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- breadcrumb-area-end --> */}

            {/* <!-- community-area --> */}
            <div className="community-area pt-30 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="community-img">
                                <img src="assets/img/images/community_img.png" alt="" />
                            </div>
                            <div className="community-content">
                                <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- community-area-end --> */}


            <TeamAreaPage/>
            <NewsLetterPage/>

           

            <section className="brand-area-two pt-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center mb-80">
                                <span className="sub-title">Our Partners</span>
                                <h2 className="title">People Who Trust Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row brand-active">
                      <BrandPage/>
                    </div>
                </div>
            </section>
   </main>
   
   </>
  )
}

export default TeamPage